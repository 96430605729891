





























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import DatePicker from "@/vue/components/DatePicker.vue"
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { Document, IDocument } from "@/model/Document";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        DatePicker
    } 
})

export default class UploadExpiryDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("uploadForm") private readonly uploadForm!: VForm;  

    private showDialogue: boolean = false;
    private upload: Document = new Document();

    //
    // -- methods
    //

    async open(uploadID: string) {
        this.reset();
        const uploadData: IDocument = await apiClient.get(`api/document/load?id=${uploadID}`);
        this.upload.update(uploadData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.upload);
        this.uploadForm?.resetValidation();
    }

    private async save() {
        if (!this.uploadForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/document/saveUploadExpiry", this.upload);
        toastr.success("Saved");
        this.$emit("reload");
        this.showDialogue = false;
    }
}
