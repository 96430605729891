import { ISAQQuestion, SAQQuestion } from "../../SAQQuestion";
import { IAssessmentAnswer, AssessmentAnswer } from "../../AssessmentAnswer";
import { VerificationTaskActionVM,IVerificationTaskActionVM } from './VerificationTaskActionVM';
import { AssessmentAnswerUploadVM, IAssessmentAnswerUploadVM } from "./AssessmentAnswerUploadVM";
import { VerificationTaskAction } from "../../VerificationTaskAction";
import { SAQQuestionUpload } from "../../SAQQuestionUpload";
import { AssessmentAnswerUpload } from "../../AssessmentAnswerUpload";
import { Document } from "../../Document";
import { ICodePointVM, CodePointVM } from "./CodePointVM";
import { CodePoint } from "../../CodePoint";
import { SAQQuestionCodePoint } from "../../SAQQuestionCodePoint";
import { mapData } from "@/utilities/DataMapping";

export interface IQuestionVM {
    question: ISAQQuestion;
    answer: IAssessmentAnswer;
    actions: Array<IVerificationTaskActionVM>;
    answerResponseDescription: string;
    answerUploads: Array<IAssessmentAnswerUploadVM>;
    codePoint: Array<ICodePointVM>;
}

export class QuestionVM implements IQuestionVM {

    constructor(data?: IQuestionVM) {
        if (data) this.update(data);
    }

    update(data: IQuestionVM) {
        mapData(data, { 
            root: () => this,
            question: () => new SAQQuestion(),
            answer: () => new AssessmentAnswer(),
            actions: () => new VerificationTaskActionVM(),
            answerUploads: () => new AssessmentAnswerUploadVM(),
            action: () => new VerificationTaskAction(),
            actionUploads: () => new Document(),
            questionUpload: () => new SAQQuestionUpload(),
            answerUpload: () => new AssessmentAnswerUpload(),
            uploads: () => new Document(),
            codePoints: () => new CodePointVM(),
            codePoint: () => new CodePoint(),
            saqQuestionCodePoint: () => new SAQQuestionCodePoint()
        });
    }

    question: any = new SAQQuestion();
    answer: any = new AssessmentAnswer();
    actions: Array<VerificationTaskActionVM> = [];
    answerResponseDescription: string = "";
    answerUploads: Array<AssessmentAnswerUploadVM> = [];
    codePoint: Array<CodePointVM> = [];

    get defaultBlobColour() {
        let retVal = "green";

        if (this.answer.supportingText == "" && this.answer.responseID == 0) {
            retVal = "red";
        } else retVal = "green";

        this.answerUploads.forEach(au => {
            if (!au.answerUpload.completed && retVal == "green") {
                retVal = "#FFBF00";
            }
        });

        return retVal;
    }

    get uploadIconColour() {
        let retVal = "green";

        this.answerUploads.forEach(au => {
            if (!au.answerUpload.completed) {
                retVal = "red";
            }
        });
        
        return retVal;
    }

    get draftBlobColour() {
        if (this.answer.visitResponseComplete) return "green";
        else return "red";
    }

    get responseTextColour() {
        if (this.answerResponseDescription == "Met") return "green";
        if (this.answerResponseDescription == "Partially met") return "#FFBF00";
        if (this.answerResponseDescription == "Not met") return "red";
    }
}
