import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IVerifierComment {
    id: string;
    verificationTaskID: string;
    commentTypeID: number;
    verifierResponse: string;
    verifierResponseID: number;
    createdDate: Date;
    createdByUserID: string;
    lastModifiedDate: Date;
    lastModifiedByUserID: string;
}

export class VerifierComment implements IVerifierComment {

    constructor(data?: IVerifierComment) {
        if (data) this.update(data);
    }

    update(data: IVerifierComment) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    verificationTaskID: string = utils.emptyGuidValue;
    commentTypeID: number = 0;
    verifierResponse: string = "";
    verifierResponseID: number = 0;
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastModifiedDate: Date = new Date(utils.emptyDateValue);
    lastModifiedByUserID: string = utils.emptyGuidValue;
}
