































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import DatePicker from "@/vue/components/DatePicker.vue"
import utils from "@/utilities/Utils";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { AssessmentAnswerUploadVM } from "@/model/Api/VM/AssessmentAnswerUploadVM";
import { IDocument } from "@/model/Document";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Prop } from "vue-property-decorator";
import { Ref } from "vue-property-decorator";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import { QuestionVM } from "@/model/Api/VM/QuestionVM";
import { VerificationTaskActionVM } from "@/model/Api/VM/VerificationTaskActionVM";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        DatePicker
    } 
})

export default class UploadDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("uploadForm") private readonly uploadForm!: VForm;  

    @Prop({}) private assessment !: AssessmentFormVM;
    @Prop({}) private section !: SectionVM;
    @Prop({}) private question !: QuestionVM;
    @Prop({}) private answerUpload !: AssessmentAnswerUploadVM;
    @Prop({}) private action !: VerificationTaskActionVM;
    private showDialogue: boolean = false;
    private upload: AssessmentAnswerUploadVM = new AssessmentAnswerUploadVM();
    private uploadDoc: File | null = null;
    private expiryDate: Date = new Date();
    private requiresExpiry: boolean = false;

    //
    // -- methods
    //

    async open(requiresExpiry: boolean) {
        this.reset();
        this.uploadForm?.resetValidation();
        this.requiresExpiry = requiresExpiry;
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.upload);
        this.uploadDoc = null;
        this.expiryDate = new Date();
        this.uploadForm?.resetValidation();
    }

    private async uploadDocument() {
        if (this.uploadDoc != null && this.uploadDoc.size > 0 && this.uploadDoc.size <= 20000000) {
            const uploadResponse: { success: boolean; document: IDocument } = await apiClient.uploadFile("api/document/uploadDocument", this.uploadDoc);
            if (!uploadResponse.success) {
                toastr.warning("Failed to upload document");
                return;
            } else {
                uploadResponse.document.assessmentID = this.assessment.assessment.id;
                if (this.answerUpload != null) {
                    uploadResponse.document.answerUploadID = this.answerUpload.answerUpload.id;
                }
                if (this.action != null) {
                    uploadResponse.document.actionID = this.action.action.id;
                }
                uploadResponse.document.expiryDate = this.expiryDate;
                const response: ISaveResponse = await apiClient.post("/api/document/saveDocument", uploadResponse.document);
                if (response.isSuccess) {
                    toastr.success("Document uploaded successfully");
                    this.$emit("reload");
                    this.showDialogue = false;
                } else {
                    toastr.warning("Failed to upload document");
                    return;
                }
            }
        } else {
            toastr.warning("Please check you have added a document to upload and it is under 20MB in size");
        }
    }

}
