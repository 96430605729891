



























































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import fileDownload from "@/utilities/FileDownload";
import store from "@/store/store";
import UploadComponent from "@/vue/components/AssessmentPages/AssessmentComponents/UploadComponent.vue";
import UploadFraDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/UploadFraDialogue.vue";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { IPropertyDocument } from "@/model/PropertyDocument";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Prop } from "vue-property-decorator";
import { UserRole, AssessmentStatus } from "@/model/Enums";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ConfirmDialogue,
        UploadComponent,
        UploadFraDialogue
    }
})
export default class UploadPage extends Vue {

    //
    // -- properties
    //

    @Prop({}) private orgAssessment !: AssessmentFormVM;
    @Prop({}) private propAssessment !: AssessmentFormVM;
    deleteDocumentName: string = "";
    
    //
    // -- computed properties
    //

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    private get canUploadDelete() {
        var retVal = false;

        if (this.propAssessment != undefined) {
            retVal = this.propAssessment.isSubmitted;
        } else {
            retVal = this.orgAssessment.isSubmitted;
        }

        return retVal;
    }

    //
    // -- methods
    //

    reload() {
        this.$emit("reload");
    }

    private async addFRAUpload(propertyID: string) {
        const dialog: UploadFraDialogue = this.$refs.uploadFraDialogue as UploadFraDialogue;
        dialog.open(propertyID);
    }

    private async downloadDocument(document: IPropertyDocument) {
        if (document.name == "" || document.name == null) {
            toastr.warning("Document is not available.");
        } else {
            await fileDownload.download(`/api/document/downloadFraDocument?name=${document.name}`, document.originalName);
        }
    }
    
    private deleteDocument(documentName: string) {
        this.deleteDocumentName = documentName;
        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }
    
    private async doDelete() {
        const response: ISaveResponse = await apiClient.get(`/api/document/fraDocumentDelete?name=${this.deleteDocumentName}`);
        if (response.isSuccess) {
            toastr.warning("Document deleted");
        } else {
            toastr.warning("Failed to delete document");
            return;
        }

        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.hide();
        
        this.$emit("reload");
    }

}
