import { ISAQSection, SAQSection } from "../../SAQSection";
import { IAssessmentSection, AssessmentSection } from "../../AssessmentSection";
import { QuestionVM, IQuestionVM } from './QuestionVM';
import { SAQQuestion } from "../../SAQQuestion";
import { AssessmentAnswer } from "../../AssessmentAnswer";
import { VerificationTaskActionVM } from './VerificationTaskActionVM';
import { AssessmentAnswerUploadVM } from "./AssessmentAnswerUploadVM";
import { VerificationTaskAction } from "../../VerificationTaskAction";
import { SAQQuestionUpload } from "../../SAQQuestionUpload";
import { AssessmentAnswerUpload } from "../../AssessmentAnswerUpload";
import { Document } from "../../Document";
import { CodePointVM } from "./CodePointVM";
import { CodePoint } from "../../CodePoint";
import { SAQQuestionCodePoint } from "../../SAQQuestionCodePoint";
import { mapData } from "@/utilities/DataMapping";

export interface ISectionVM {
    saqSection: ISAQSection;
    assessmentSection: IAssessmentSection;
    questionCount: number;
    answeredQuestionCount: number;
    visitResponseCompleteQuestionCount: number;
    actionCount: number;
    respondedActionCount: number;
    disputedActionCount: number;
    sectionNumber: number;
    questions: Array<IQuestionVM>;
}

export class SectionVM implements ISectionVM {

    constructor(data?: ISectionVM) {
        if (data) this.update(data);
    }

    update(data: ISectionVM) {
        mapData(data, { 
            root: () => this,
            saqSection: () => new SAQSection(),
            assessmentSection: () => new AssessmentSection(),
            questions: () => new QuestionVM(),
            question: () => new SAQQuestion(),
            answer: () => new AssessmentAnswer(),
            actions: () => new VerificationTaskActionVM(),
            answerUploads: () => new AssessmentAnswerUploadVM(),
            action: () => new VerificationTaskAction(),
            actionUploads: () => new Document(),
            questionUpload: () => new SAQQuestionUpload(),
            answerUpload: () => new AssessmentAnswerUpload(),
            uploads: () => new Document(),
            codePoints: () => new CodePointVM(),
            codePoint: () => new CodePoint(),
            saqQuestionCodePoint: () => new SAQQuestionCodePoint()
        });
    }

    saqSection: any = new SAQSection();
    assessmentSection: any = new AssessmentSection();
    questionCount: number = 0;
    answeredQuestionCount: number = 0;
    visitResponseCompleteQuestionCount: number = 0;
    actionCount: number = 0;
    respondedActionCount: number = 0;
    disputedActionCount: number = 0;
    sectionNumber: number = 0;
    questions: Array<QuestionVM> = [];

    isSelected: boolean = false;

    get defaultBlobColour() {
        if (this.questionCount > 0 && this.questionCount == this.answeredQuestionCount && this.actionCount == this.respondedActionCount) {
            return "green";
        } else if (
                    (this.questionCount > 0 && this.answeredQuestionCount > 0 && this.questionCount > this.answeredQuestionCount) ||
                    (this.questionCount == this.answeredQuestionCount && this.actionCount > 0 && this.respondedActionCount > 0 && this.actionCount > this.respondedActionCount)
                ) {
            return "#FFBF00";
        } else if ((this.questionCount > 0 && this.answeredQuestionCount == 0) || (this.questionCount == this.answeredQuestionCount && this.actionCount > 0 && this.respondedActionCount == 0)) {
            return "red";
        } else return "grey";
    }

    get draftBlobColour() {
        let retVal = "#FFBF00";
        const totalQuestions = this.questions.length;
        let completedQuestions = 0;

        this.questions.forEach(q => {
            if (q.answer.visitResponseComplete) {
                completedQuestions += 1;
            }
        })

        if (totalQuestions == completedQuestions) retVal = "green";
        if (totalQuestions > 0 && completedQuestions == 0) retVal = "red";

        return retVal;
    }

    get draftReviewBlobColour() {
        if (this.actionCount == 0) {
            return "grey";
        } else if (this.actionCount == this.respondedActionCount) {
            return "green";
        } else if (this.actionCount > 0 && this.respondedActionCount == 0) {
            return "red";
        } else {
            return "#FFBF00";
        }
    }

    get disputeBlobColour() {
        if (this.disputedActionCount == 0) {
            return "grey";
        } else {
            return "red";
        }
    }
}
