import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IAssessmentAnswerUpload {
    id: string;
    assessmentAnswerID: string;
    saqQuestionUploadID: string;
    completed: boolean;
    uploadFailureReason: string;
}

export class AssessmentAnswerUpload implements IAssessmentAnswerUpload {

    constructor(data?: IAssessmentAnswerUpload) {
        if (data) this.update(data);
    }

    update(data: IAssessmentAnswerUpload) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    assessmentAnswerID: string = utils.emptyGuidValue;
    saqQuestionUploadID: string = utils.emptyGuidValue;
    completed: boolean = false;
    uploadFailureReason: string = "";
}
