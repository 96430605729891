





































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import SectionComponent from "@/vue/components/AssessmentPages/AssessmentComponents/SectionComponent.vue";
import QuestionComponent from "@/vue/components/AssessmentPages/AssessmentComponents/QuestionComponent.vue";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Prop } from "vue-property-decorator";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        SectionComponent,
        QuestionComponent
    }
})
export default class QuestionnairePage extends Vue {

    //
    // -- properties
    //

    @Prop({}) private orgAssessment !: AssessmentFormVM;
    @Prop({}) private propAssessment !: AssessmentFormVM;
    @Prop({}) private section !: SectionVM;
    @Prop({}) private assessmentType !: string;
    @Prop({}) private sectionNumber !: number;

    get sectionForwardDisabled() {
        var sectionsCount = this.orgAssessment.sections.length;

        if (this.propAssessment !== null) {
            sectionsCount += this.propAssessment.sections.length;
        }

        return sectionsCount == this.sectionNumber;
    }

    //
    // -- methods
    //

    setSection(section: SectionVM) {
        this.$emit("setSection", section)   
    }

    sectionForward() {
        this.$emit("sectionForward")   
    }

    sectionBack() {
        this.$emit("sectionBack")   
    }

    // resetSection() {
    //     this.assessment.sections.forEach(s => {
    //         if (s.assessmentSection.id == this.section.assessmentSection.id && this.section.isSelected) {
    //             s.isSelected = true;
    //             this.section = s;
    //         }
    //     })
    // }

    reload() {
        this.$emit("reload");
    }

    private async submitAssessment() {
        const response: ISaveResponse = await apiClient.post("/api/assessment/submitAssessment", this.orgAssessment.assessment);
        if (response.isSuccess) {
            toastr.success("Submitted");
            this.$router.push("/assessments/");
        }
    }
}
