import { ICodePoint, CodePoint } from "../../CodePoint";
import { ISAQQuestionCodePoint, SAQQuestionCodePoint } from "../../SAQQuestionCodePoint";
import { mapData } from "@/utilities/DataMapping";

export interface ICodePointVM {
    clause: string;
    codePoint: ICodePoint;
    saqQuestionCodePoint: ISAQQuestionCodePoint;
}

export class CodePointVM implements ICodePointVM {

    constructor(data?: ICodePointVM) {
        if (data) this.update(data);
    }

    update(data: ICodePointVM) {
        mapData(data, { 
            root: () => this,
            codePoint: () => new CodePoint(),
            saqQuestionCodePoint: () => new SAQQuestionCodePoint()
        });
    }

    clause: string = "";
    codePoint: any = new CodePoint();
    saqQuestionCodePoint: any = new SAQQuestionCodePoint();
}