import { IPropertyDocument, PropertyDocument } from "../../PropertyDocument";
import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IPropertyFraVM {
    propertyID: string;
    propertyName: string;
    friDocument: IPropertyDocument;
}

export class PropertyFraVM implements IPropertyFraVM {

    constructor(data?: IPropertyFraVM) {
        if (data) this.update(data);
    }

    update(data: IPropertyFraVM) {
        mapData(data, { 
            root: () => this,
            friDocument: () => new PropertyDocument(),
        });
    }

    propertyID: string = utils.emptyGuidValue;
    propertyName: string = "";
    friDocument: any = new PropertyDocument();

    get blobColour() {
        let retVal = "green";

        if (this.friDocument.name == "") {
            retVal = "red";
        }

        return retVal;
    }

    get uploadButtonText() {
        if (this.friDocument.sizeBytes == 0) {
            return "Upload New";
        } else return "Replace Upload";
    }
}
