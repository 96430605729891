

























































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import ActionDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/ActionDialogue.vue";
import ActionResponseDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/ActionResponseDialogue.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import fileDownload from "@/utilities/FileDownload";
import store from "@/store/store";
import UploadDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/UploadDialogue.vue";
import Utils from "@/utilities/Utils";
import VerificationActionHistoryDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/VerificationActionHistoryDialogue.vue";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { IDocument } from "@/model/Document";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Prop } from "vue-property-decorator";
import { QuestionVM } from "@/model/Api/VM/QuestionVM";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import { UserRole, AssessmentStatus } from "@/model/Enums";
import { VerificationTaskActionVM } from "@/model/Api/VM/VerificationTaskActionVM";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ActionDialogue,
        ActionResponseDialogue,
        ConfirmDialogue,
        UploadDialogue,
        VerificationActionHistoryDialogue
    }
})
export default class ActionComponent extends Vue {

    //
    // -- properties
    //
    
    @Prop({}) private assessment !: AssessmentFormVM;
    @Prop({}) private section !: SectionVM;
    @Prop({}) private question !: QuestionVM;
    @Prop({}) private action !: VerificationTaskActionVM;
    deleteDocumentID: string = Utils.emptyGuidValue;
    
    //
    // -- computed properties
    //

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    private get isVerifier() {
        return store.state.signedInUser?.role == UserRole.Verifier;
    }

    private get replyButtonVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.DraftReview ||
                this.assessment.assessment.status == AssessmentStatus.DisputedDraft
    }

    private get verifierResponseButtonsVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.Visit ||
                this.assessment.assessment.status == AssessmentStatus.Dispute ||
                this.assessment.assessment.status == AssessmentStatus.Verified ||
                this.assessment.assessment.status == AssessmentStatus.Completed
    }

    //
    // -- methods
    //

    viewTimeline() {
        const dialog: VerificationActionHistoryDialogue = this.$refs.verificationActionHistoryDialogue as VerificationActionHistoryDialogue;
        dialog.open(this.action.action);
    }

    actionDispute() {
        const dialog: ActionResponseDialogue = this.$refs.actionResponseDialogue as ActionResponseDialogue;
        dialog.open(this.action.action);
    }

    private async actionAccept() {
        this.action.action.disputed = false;
        const response: ISaveResponse = await apiClient.post("/api/action/saveVerificationActionResponse", this.action.action);
        toastr.success("Saved");
        this.$emit("reload");
    }

    private async toggleAlert() {
        const response: ISaveResponse = await apiClient.post("/api/action/toggleActionAlert", this.action.action);
        toastr.success("Action Alert Saved");
        this.$emit("reload");
    }

    editAction() {
        const dialog: ActionDialogue = this.$refs.actionDialogue as ActionDialogue;
        dialog.edit(this.action.action.id, this.question.question.question);
    }

    private async actionComplete() {
        const response: ISaveResponse = await apiClient.post("/api/action/completeAction", this.action.action);
        toastr.success("Action Marked Completed");
        this.$emit("reload");
    }

    private deleteAction() {
        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }

    private uploadFile() {
        const dialog: UploadDialogue = this.$refs.uploadDialogue as UploadDialogue;
        dialog.open(false);
    }

    private async doDelete() {
        if (this.assessment.assessment.status == AssessmentStatus.Visit) {
            const response: ISaveResponse = await apiClient.post("/api/action/perminantlyDeleteVerificationAction", this.action.action);
        } else {
            const response: ISaveResponse = await apiClient.post("/api/action/deleteVerificationAction", this.action.action);
        }
        toastr.warning("Action Deleted");

        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.hide();

        this.$emit("reload");
    }

    private async downloadDocument(document: IDocument) {
        if (document.id == Utils.emptyGuidValue || document.id == null) {
            toastr.warning("Document is not available.");
        } else {
            await fileDownload.download(`/api/document/downloadDocument?id=${document.id}`, document.originalFilename);
        }
    }

    private deleteDocument(documentID: string) {
        this.deleteDocumentID = documentID;
        const dialog: ConfirmDialogue = this.$refs.deleteUploadDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doDocumentDelete() {
        const response: ISaveResponse = await apiClient.get(`/api/document/documentDelete?id=${this.deleteDocumentID}`);
        if (response.isSuccess) {
            toastr.warning("Document deleted");
        } else {
            toastr.warning("Failed to delete document");
            return;
        }

        const dialog: ConfirmDialogue = this.$refs.deleteUploadDialogue as ConfirmDialogue;
        dialog.hide();
        
        this.$emit("reload");
    }

    reload() {
        this.$emit("reload");
    }
}
