import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ISAQQuestion {
    id: string;
    saqSectionID: string;
    question: string;
    helpText: string;
    displayOrder: number;
    createdDate: Date;
    createdByUserID: string;
    lastModifiedDate: Date;
    lastModifiedByUserID: string;
}

export class SAQQuestion implements ISAQQuestion {

    constructor(data?: ISAQQuestion) {
        if (data) this.update(data);
    }

    update(data: ISAQQuestion) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    saqSectionID: string = utils.emptyGuidValue;
    question: string = "";
    helpText: string = "";
    displayOrder: number = 0;
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastModifiedDate: Date = new Date(utils.emptyDateValue);
    lastModifiedByUserID: string = utils.emptyGuidValue;
}
