






































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { VerificationTaskAction } from "@/model/VerificationTaskAction";
import { IVerificationTaskActionHistory } from "@/model/VerificationTaskActionHistory";

@Component({ components: { 
    ApiButton } 
})

export default class VerificationActionHistoryDialogue extends Vue {

    //
    // -- properties
    //

    private showDialogue: boolean = false;
    private action: VerificationTaskAction = new VerificationTaskAction();
    private histories: Array<IVerificationTaskActionHistory> = [];

    //
    // -- methods
    //

    async open(action: VerificationTaskAction) {
        this.action = action;
        await this.loadActionHistory();
        this.showDialogue = true;
    }

    private async loadActionHistory() {
        this.histories = [];
        const response = await apiClient.get(`/api/action/LoadHistoryRecords?id=${this.action.id}`);
        this.histories.push(...response);
    }
}
