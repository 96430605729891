import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IAssessmentSection {
    id: string;
    assessmentID: string;
    saqSectionID: string;
    sectionNo: number;
}

export class AssessmentSection implements IAssessmentSection {

    constructor(data?: IAssessmentSection) {
        if (data) this.update(data);
    }

    update(data: IAssessmentSection) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    assessmentID: string = utils.emptyGuidValue;
    saqSectionID: string = utils.emptyGuidValue;
    sectionNo: number = 0;
}
