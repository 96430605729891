

































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref, Watch } from "vue-property-decorator";
import { VerificationTaskAction } from "@/model/VerificationTaskAction";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton
    }
})

export default class ActionResponseDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("responseForm") private readonly responseForm!: VForm;

    private showDialogue: boolean = false;
    private action: VerificationTaskAction = new VerificationTaskAction();

    @Watch("action.disputed")
    private disputedValidateReason() {
        this.responseForm.validate()
    }

    //
    // -- methods
    //

    async open(action: VerificationTaskAction) {
        this.reset();
        this.action = action;
        this.action.disputed = true;
        this.responseForm?.resetValidation();
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.action);
        this.responseForm?.resetValidation();
    }

    private cancel() {
        this.$emit("reload");
        this.showDialogue = false;
    }

    private async save() {
        if (!this.responseForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/action/saveVerificationActionResponse", this.action);
        toastr.success("Saved");
        this.$emit("reload");
        this.showDialogue = false;
    }
}
