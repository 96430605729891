import { IVerifierComment, VerifierComment } from "../../VerifierComment";
import { mapData } from "@/utilities/DataMapping";

export interface IVerifierCommentVM {
    verifierComment: IVerifierComment;
    commentDescription: string;
    verifierResponseDescription: string;
}

export class VerifierCommentVM implements IVerifierCommentVM {

    constructor(data?: IVerifierCommentVM) {
        if (data) this.update(data);
    }

    update(data: IVerifierCommentVM) {
        mapData(data, { 
            root: () => this,
            verifierComment: () => new VerifierComment(),
        });
    }

    verifierComment: any = new VerifierComment();
    commentDescription: string = "";
    verifierResponseDescription: string = "";

    get verifierIconColour() {
        if (this.verifierComment.verifierResponse != "" || this.verifierComment.verifierResponseID > 0) {
            return "green";
        } else return "red";
    }

    get responseButtonText() {
        if (this.verifierComment.verifierResponse != "" || this.verifierComment.verifierResponseID > 0) {
            return "Edit Response";
        } else return "Add Response";
    }
}
