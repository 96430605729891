
































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import fileDownload from "@/utilities/FileDownload";
import store from "@/store/store";
import UploadDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/UploadDialogue.vue";
import UploadExpiryDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/UploadExpiryDialogue.vue";
import UploadFailureDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/UploadFailureDialogue.vue";
import Utils from "@/utilities/Utils";
import { Prop, Watch } from "vue-property-decorator";
import { AssessmentAnswerUploadVM } from "@/model/Api/VM/AssessmentAnswerUploadVM";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { IDocument } from "@/model/Document";
import { ISaveResponse } from "@/model/ISaveResponse";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import { QuestionVM } from "@/model/Api/VM/QuestionVM";
import { UserRole } from "@/model/Enums";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ConfirmDialogue,
        UploadDialogue,
        UploadExpiryDialogue,
        UploadFailureDialogue
    }
})
export default class UploadComponent extends Vue {

    //
    // -- properties
    //

    @Prop({}) private assessment !: AssessmentFormVM;
    @Prop({}) private section !: SectionVM;
    @Prop({}) private question !: QuestionVM;
    @Prop({}) private upload !: AssessmentAnswerUploadVM;
    private uploadDoc: File | null = null;
    deleteDocumentID: string = Utils.emptyGuidValue;
    
    //
    // -- computed properties
    //

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    private get isVerifier() {
        return store.state.signedInUser?.role == UserRole.Verifier;
    }

    @Watch("uploadDoc")
    private onUploadDocChanged(val: any) {
        if (val.length == 0) {
            this.uploadDoc = null;
        } else {
            this.uploadDocument();
        }
    }

    //
    // -- methods
    //

    private async uploadDocument() {
        if (this.uploadDoc != null && this.uploadDoc.size > 0) {
            const uploadResponse: { success: boolean; document: IDocument } = await apiClient.uploadFile("api/document/uploadDocument", this.uploadDoc);
            if (!uploadResponse.success) {
                toastr.warning("Failed to upload document");
                return;
            } else {
                uploadResponse.document.assessmentID = this.assessment.assessment.id;
                uploadResponse.document.answerUploadID = this.upload.answerUpload.id;
                const response: ISaveResponse = await apiClient.post("/api/document/saveDocument", uploadResponse.document);
                if (response.isSuccess) {
                    toastr.success("Document uploaded successfully");
                    this.$emit("reload");
                } else {
                    toastr.warning("Failed to upload document");
                    return;
                }
            }
        }
    }
    
    private async addUpload() {
        const dialog: UploadDialogue = this.$refs.uploadDialogue as UploadDialogue;
        dialog.open(true);
    }

    private async downloadDocument(document: IDocument) {
        if (document.id == Utils.emptyGuidValue || document.id == null) {
            toastr.warning("Document is not available.");
        } else {
            await fileDownload.download(`/api/document/downloadDocument?id=${document.id}`, document.originalFilename);
        }
    }

    private editExpiry(documentID: string) {
        const dialog: UploadExpiryDialogue = this.$refs.uploadExpiryDialogue as UploadExpiryDialogue;
        dialog.open(documentID);
    }

    private deleteDocument(documentID: string) {
        this.deleteDocumentID = documentID;
        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doDelete() {
        const response: ISaveResponse = await apiClient.get(`/api/document/documentDelete?id=${this.deleteDocumentID}`);
        if (response.isSuccess) {
            toastr.warning("Document deleted");
        } else {
            toastr.warning("Failed to delete document");
            return;
        }

        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.hide();
        
        this.$emit("reload");
    }

    private async markUploadsComplete() {
        if (this.upload.uploads.length > 0) {
            const response: ISaveResponse = await apiClient.post("/api/assessment/saveAnswerUpload", this.upload.answerUpload);
            toastr.success("Saved");
            this.$emit("reload");
        } else if (this.upload.questionUpload.required) {
            toastr.error("Please upload the required file to continue")
        } else {
            const dialog: UploadFailureDialogue = this.$refs.uploadFailureDialogue as UploadFailureDialogue;
            dialog.open(this.upload.answerUpload.id, this.upload.questionUpload.title);
        }
    }

    reload() {
        this.$emit("reload");
    }
    
}
