import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IPropertyDocument {
    name: string;
    propertyID: string;
    typeID: string;
    path: string;
    originalName: string;
    mimeType: string;
    extension: string;
    sizeBytes: number;
    createdDate: Date;
    createdByUserID: string;
}

export class PropertyDocument implements IPropertyDocument {

    constructor(data?: IPropertyDocument) {
        if (data) this.update(data);
    }

    update(data: IPropertyDocument) {
        mapData(data, { root: () => this });
    }

    name: string = "";
    propertyID: string = utils.emptyGuidValue;
    typeID: string = utils.emptyGuidValue;
    path: string = "";
    originalName: string = "";
    mimeType: string = "";
    extension: string = "";
    sizeBytes: number = 0;
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
}
