





























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import store from "@/store/store";
import { Prop } from "vue-property-decorator";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import { AssessmentStatus, UserRole } from "@/model/Enums";

@Component({
    components: { 
        ApiButton
    }
})
export default class SectionComponent extends Vue {

    //
    // -- properties
    //

    @Prop({}) private assessment !: AssessmentFormVM;
    @Prop({}) private section !: SectionVM;
    
    //
    // -- computed properties
    //

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    private get isVerifier() {
        return store.state.signedInUser?.role == UserRole.Verifier;
    }

    private get questionCountVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.OSAQ ||
                this.assessment.assessment.status == AssessmentStatus.AssignVerifier ||
                this.assessment.assessment.status == AssessmentStatus.ScheduleVisit ||
                this.assessment.assessment.status == AssessmentStatus.SSAQ
    }

    private get actionReviewVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.DraftReview || 
                this.assessment.assessment.status == AssessmentStatus.DisputedDraft;
    }

    private get draftReviewBlobVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.DraftReview ||
                this.assessment.assessment.status == AssessmentStatus.DisputedDraft;
    }

    private get disputeBlobVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.Dispute;
    }

    private get defaultBlobVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.OSAQ ||
                this.assessment.assessment.status == AssessmentStatus.AssignVerifier ||
                this.assessment.assessment.status == AssessmentStatus.ScheduleVisit ||
                this.assessment.assessment.status == AssessmentStatus.SSAQ
    }

    private get draftBlobVisible() {
        return this.assessment.assessment.status == AssessmentStatus.Visit
    }

    //
    // -- methods
    //

    setSection() {
        this.$emit("setSection", this.section);
    }
}
