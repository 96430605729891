import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ICodePoint {
    id: string;
    number: number;
    description: string;
    subSectionID: string;
    sectionID: string;
    codeID: string;
}

export class CodePoint implements ICodePoint {

    constructor(data?: ICodePoint) {
        if (data) this.update(data);
    }

    update(data: ICodePoint) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    number: number = 0;
    description: string = "";
    subSectionID: string = utils.emptyGuidValue;
    sectionID: string = utils.emptyGuidValue;
    codeID: string = utils.emptyGuidValue;
}
