

































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { AssessmentAnswerUpload, IAssessmentAnswerUpload } from "@/model/AssessmentAnswerUpload";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton
    } 
})

export default class UploadFailureDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("uploadFailureForm") private readonly uploadFailureForm!: VForm;  

    private showDialogue: boolean = false;
    title: string = "";
    private upload: AssessmentAnswerUpload = new AssessmentAnswerUpload();

    //
    // -- methods
    //

    async open(id: string, title: string) {
        this.reset();
        this.title = title;
        const uploadData: IAssessmentAnswerUpload = await apiClient.get(`api/assessment/loadAssessmentAnswerUpload?id=${id}`);
        this.upload.update(uploadData);
        this.uploadFailureForm?.resetValidation();
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.upload);
        this.uploadFailureForm?.resetValidation();
    }

    private async save() {
        if (!this.uploadFailureForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/assessment/saveAnswerUpload", this.upload);
        toastr.success("Saved");
        this.$emit("reload");    
        this.showDialogue = false;
    }
}
