import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ISAQQuestionCodePoint {
    saqQuestionID: string;
    codePointID: string;
}

export class SAQQuestionCodePoint implements ISAQQuestionCodePoint {

    constructor(data?: ISAQQuestionCodePoint) {
        if (data) this.update(data);
    }

    update(data: ISAQQuestionCodePoint) {
        mapData(data, { root: () => this });
    }

    saqQuestionID: string = utils.emptyGuidValue;
    codePointID: string = utils.emptyGuidValue;
}
