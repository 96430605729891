


































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { ILookupItem } from "@/model/LookupItem";
import { IQuestionVM, QuestionVM } from "@/model/Api/VM/QuestionVM";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Prop } from "vue-property-decorator";
import { Ref, Watch } from "vue-property-decorator";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
    ApiButton } 
})

export default class AssessmentQuestionTextDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("answerForm") private readonly answerForm!: VForm;  

    private showDialogue: boolean = false;
    private question: QuestionVM = new QuestionVM();
    private answerTypes: Array<ILookupItem> = [];
    sectionNumber: number = 0;
    metResponseID: number = 0;

    @Watch("question.answer.responseID")
    private responseIDValidateSupportingText() {
        this.answerForm.validate()
    }

    //
    // -- methods
    //

    async edit(id: string, sectionNumber: number) {
        this.reset();
        this.sectionNumber = sectionNumber;
        this.loadAnswerTypes();
        const questionData: IQuestionVM = await apiClient.get(`api/assessment/LoadQuestion?id=${id}`);
        this.question.update(questionData);
        this.answerForm?.resetValidation();
        this.showDialogue = true;
    }

    private async loadAnswerTypes() {
        this.answerTypes = [];
        const response = await apiClient.get("/api/assessment/LoadAnswerTypes");
        this.answerTypes.push(...response);
        const metLookup = this.answerTypes.filter(at => at.description == "Met");
        this.metResponseID = metLookup[0].id;
    }

    private reset() {
        utils.resetObject(this.question);
        this.answerForm?.resetValidation();
    }

    private async save() {
        if (!this.answerForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/assessment/saveAssessmentAnswer", this.question.answer);
        toastr.success("Saved");
        this.$emit("reload");    
        this.showDialogue = false;
    }
}
