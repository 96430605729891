






























































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ActionComponent from "@/vue/components/AssessmentPages/AssessmentComponents/ActionComponent.vue";
import AssessmentQuestionTextDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/AssessmentQuestionTextDialogue.vue";
import ActionDialogue from "@/vue/components/AssessmentPages/AssessmentComponents/ActionDialogue.vue";
import store from "@/store/store";
import { AssessmentFormVM } from "@/model/Api/VM/AssessmentFormVM";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Prop } from "vue-property-decorator";
import { SectionVM } from "@/model/Api/VM/SectionVM";
import { QuestionVM } from "@/model/Api/VM/QuestionVM";
import { UserRole, AssessmentStatus } from "@/model/Enums";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ActionComponent,
        AssessmentQuestionTextDialogue,
        ActionDialogue
    }
})
export default class QuestionComponent extends Vue {

    //
    // -- properties
    //

    @Prop({}) private assessment !: AssessmentFormVM;
    @Prop({}) private section !: SectionVM;
    @Prop({}) private question !: QuestionVM;
    
    //
    // -- computed properties
    //

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    private get isVerifier() {
        return store.state.signedInUser?.role == UserRole.Verifier;
    }

    private get defaultBlobVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.OSAQ ||
                this.assessment.assessment.status == AssessmentStatus.AssignVerifier ||
                this.assessment.assessment.status == AssessmentStatus.ScheduleVisit ||
                this.assessment.assessment.status == AssessmentStatus.SSAQ
    }

    private get draftBlobVisible() {
        return this.assessment.assessment.status == AssessmentStatus.Visit
    }

    private get canRaiseAction() {
        return  this.assessment.assessment.status == AssessmentStatus.Visit ||
                this.assessment.assessment.status == AssessmentStatus.Dispute
    }

    get actionsVisible() {
        return  this.assessment.assessment.status == AssessmentStatus.DraftReview ||
                this.assessment.assessment.status == AssessmentStatus.Dispute ||
                this.assessment.assessment.status == AssessmentStatus.DisputedDraft ||
                this.assessment.assessment.status == AssessmentStatus.DisputeUnresolvable ||
                this.assessment.assessment.status == AssessmentStatus.Verified ||
                this.assessment.assessment.status == AssessmentStatus.Completed ||
                this.assessment.assessment.status == AssessmentStatus.Archived
    }

    //
    // -- methods
    //
    
    setSupportingText(id: string) {
        const dialog: AssessmentQuestionTextDialogue = this.$refs.assessmentQuestionTextDialogue as AssessmentQuestionTextDialogue;
        dialog.edit(id, this.section.assessmentSection.sectionNo);
    }
    
    raiseAction(id: string) {
        const dialog: ActionDialogue = this.$refs.actionDialogue as ActionDialogue;
        dialog.open(id, this.question.question.question);
    }

    async completeVisitReview(id: string) {
        await apiClient.get(`/api/assessment/markAnswerVisitReviewComplete?id=${id}`);
        toastr.success("Visit Review Question Completed");
        this.$emit("reload");
    }

    reload() {
        this.$emit("reload");
    }

}
