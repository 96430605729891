import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IVerificationTaskAction {
    id: string;
    answerID: string;
    description: string;
    dueDate: Date;
    responded: boolean;
    disputed: boolean;
    disputedReason: string;
    completedDate: Date;
    completedByUserID: string;
    alert: boolean;
    responseDate: Date;
    responseByUserID: string;
    createdDate: Date;
    createdByUserID: string;
    lastModifiedDate: Date;
    lastModifiedByUserID: string;
    deletedDate: Date;
    deletedByUserID: string;

    notes: string;
}

export class VerificationTaskAction implements IVerificationTaskAction {

    constructor(data?: IVerificationTaskAction) {
        if (data) this.update(data);
    }

    update(data: IVerificationTaskAction) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    answerID: string = utils.emptyGuidValue;
    description: string = "";
    dueDate: Date = new Date(utils.emptyDateValue);
    responded: boolean = true;
    disputed: boolean = true;
    disputedReason: string = "";
    completedDate: Date = new Date(utils.emptyDateValue);
    completedByUserID: string = utils.emptyGuidValue;
    alert: boolean = false;
    responseDate: Date = new Date(utils.emptyDateValue);
    responseByUserID: string = utils.emptyGuidValue;
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastModifiedDate: Date = new Date(utils.emptyDateValue);
    lastModifiedByUserID: string = utils.emptyGuidValue;
    deletedDate: Date = new Date(utils.emptyDateValue);
    deletedByUserID: string = utils.emptyGuidValue;

    notes: string = "";
}
