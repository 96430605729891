







































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import DatePicker from "@/vue/components/DatePicker.vue"
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { IVerificationTaskAction, VerificationTaskAction } from "@/model/VerificationTaskAction";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        DatePicker
    } 
})

export default class ActionDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("actionForm") private readonly actionForm!: VForm;  

    private showDialogue: boolean = false;
    private action: VerificationTaskAction = new VerificationTaskAction();
    private questionText: string = "";

    //
    // -- methods
    //

    async open(id: string, question: string) {
        this.reset();
        this.action.answerID = id;
        this.questionText = question;
        this.actionForm?.resetValidation();
        this.action.dueDate = new Date();
        this.showDialogue = true;
    }

    async edit(id: string, question: string) {
        this.reset();
        this.questionText = question;
        const actionData: IVerificationTaskAction = await apiClient.get(`api/action/Load?id=${id}`);
        this.action.update(actionData);
        this.action.dueDate = actionData.dueDate;
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.action);
        this.actionForm?.resetValidation();
    }

    private async save() {
        if (!this.actionForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/action/saveVerificationAction", this.action);
        toastr.success("Saved");
        this.$emit("reload");    
        this.showDialogue = false;
    }
}
