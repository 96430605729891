import { IVerificationTask, VerificationTask } from "@/model/VerificationTask";
import { IAssessment, Assessment } from "../../Assessment";
import { SectionVM, ISectionVM } from './SectionVM';
import { IVerifierCommentVM, VerifierCommentVM } from "./VerifierCommentVM";
import { VerifierComment } from "@/model/VerifierComment";
import { IPropertyFraVM, PropertyFraVM } from "./PropertyFraVM";
import { PropertyDocument } from "@/model/PropertyDocument";
import { SAQSection } from "../../SAQSection";
import { AssessmentSection } from "../../AssessmentSection";
import { QuestionVM } from './QuestionVM';
import { SAQQuestion } from "../../SAQQuestion";
import { AssessmentAnswer } from "../../AssessmentAnswer";
import { VerificationTaskActionVM } from './VerificationTaskActionVM';
import { AssessmentAnswerUploadVM } from "./AssessmentAnswerUploadVM";
import { VerificationTaskAction } from "../../VerificationTaskAction";
import { SAQQuestionUpload } from "../../SAQQuestionUpload";
import { AssessmentAnswerUpload } from "../../AssessmentAnswerUpload";
import { Document } from "../../Document";
import { CodePointVM } from "./CodePointVM";
import { CodePoint } from "../../CodePoint";
import { SAQQuestionCodePoint } from "../../SAQQuestionCodePoint";
import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IAssessmentFormVM {
    assessment: IAssessment;
    verificationTask: IVerificationTask;
    code: string;
    assessmentType: string;
    assessmentDescription: string;
    verifierStatusDescription: string;
    landlordStatusDescription: string;
    sections: Array<ISectionVM>;
    verifierComments: Array<IVerifierCommentVM>;
    propertyFras: Array<IPropertyFraVM>;
}

export class AssessmentFormVM implements IAssessmentFormVM {

    constructor(data?: IAssessmentFormVM) {
        if (data) this.update(data);
    }

    update(data: IAssessmentFormVM) {
        mapData(data, { 
            root: () => this,
            assessment: () => new Assessment(),
            verificationTask: () => new VerificationTask(),
            sections: () => new SectionVM(),
            verifierComments: () => new VerifierCommentVM(),
            verifierComment: () => new VerifierComment(),
            propertyFras: () => new PropertyFraVM(),
            friDocument: () => new PropertyDocument(),
            saqSection: () => new SAQSection(),
            assessmentSection: () => new AssessmentSection(),
            questions: () => new QuestionVM(),
            question: () => new SAQQuestion(),
            answer: () => new AssessmentAnswer(),
            actions: () => new VerificationTaskActionVM(),
            answerUploads: () => new AssessmentAnswerUploadVM(),
            action: () => new VerificationTaskAction(),
            actionUploads: () => new Document(),
            questionUpload: () => new SAQQuestionUpload(),
            answerUpload: () => new AssessmentAnswerUpload(),
            uploads: () => new Document(),
            codePoints: () => new CodePointVM(),
            codePoint: () => new CodePoint(),
            saqQuestionCodePoint: () => new SAQQuestionCodePoint()
        });
    }

    assessment: any = new Assessment();
    verificationTask: any = new VerificationTask();
    code: string = "";
    assessmentType: string = "";
    assessmentDescription: string = "";
    verifierStatusDescription: string = "";
    landlordStatusDescription: string = "";
    sections: Array<SectionVM> = [];
    verifierComments: Array<VerifierCommentVM> = [];
    propertyFras: Array<PropertyFraVM> = [];

    get isSubmitted() {
        return this.assessment.submittedByUserID != utils.emptyGuidValue;
    }

    get requestedUploads() {
        let retVal = 0;

        this.sections.forEach(s => {
            s.questions.forEach(q => {
                if (q.answerUploads.length > 0) {
                    retVal += 1;
                }
            })
        });

        return retVal;
    }
}
