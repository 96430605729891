































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import DatePicker from "@/vue/components/DatePicker.vue"
import utils from "@/utilities/Utils";
import { PropertyDocument } from "@/model/PropertyDocument";
import { IFRADocument } from "@/model/FRADocument";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Prop } from "vue-property-decorator";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        DatePicker
    } 
})

export default class UploadFraDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("uploadForm") private readonly uploadForm!: VForm;  

    @Prop({}) private propertyDocument !: PropertyDocument;
    private showDialogue: boolean = false;
    private uploadDoc: File | null = null;
    private assessmentDate: Date = new Date();
    private expiryDate: Date = new Date();
    private propertyID: string = utils.emptyGuidValue;

    //
    // -- methods
    //

    async open(propertyID: string) {
        this.reset();
        this.propertyID = propertyID;
        this.uploadForm?.resetValidation();
        this.showDialogue = true;
    }

    private reset() {
        this.uploadDoc = null;
        this.assessmentDate = new Date();
        this.expiryDate = new Date();
        this.uploadForm?.resetValidation();
    }

    private async uploadDocument() {
        if (this.uploadDoc != null && this.uploadDoc.size > 0 && this.uploadDoc.size <= 20000000) {
            const uploadResponse: { success: boolean; fraDocument: IFRADocument } = await apiClient.uploadFile("api/document/uploadFRADocument", this.uploadDoc);
            if (!uploadResponse.success) {
                toastr.warning("Failed to upload document");
                return;
            } else {
                uploadResponse.fraDocument.propertyID = this.propertyID;
                uploadResponse.fraDocument.assessmentDate = this.assessmentDate;
                uploadResponse.fraDocument.expiryDate = this.expiryDate;
                const response: ISaveResponse = await apiClient.post("/api/document/saveFRADocument", uploadResponse.fraDocument);
                if (response.isSuccess) {
                    toastr.success("Document uploaded successfully");
                    this.$emit("reload");
                    this.showDialogue = false;
                } else {
                    toastr.warning("Failed to save document details");
                    return;
                }
            }
        } else {
            toastr.warning("Please check you have added a document to upload and it is under 20MB in size");
        }
    }

}
