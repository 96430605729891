import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IDocument {
    id: string;
    key: string;
    assessmentID: string;
    answerUploadID: string;
    actionID: string;
    expiryDate: Date;
    originalFilename: string;
    systemFilename: string;
    extension: string;
    contentType: string;
    size: number;
    createdDate: Date;
    createdByUserID: string;
}

export class Document implements IDocument {

    constructor(data?: IDocument) {
        if (data) this.update(data);
    }

    update(data: IDocument) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    key: string = utils.emptyGuidValue;
    assessmentID: string = utils.emptyGuidValue;
    answerUploadID: string = utils.emptyGuidValue;
    actionID: string = utils.emptyGuidValue;
    expiryDate: Date = new Date(utils.emptyDateValue);
    originalFilename: string = "";
    systemFilename: string = "";
    extension: string = "";
    contentType: string = "";
    size: number = 0;
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
}
