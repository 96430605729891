import { IVerificationTaskAction, VerificationTaskAction } from "../../VerificationTaskAction";
import { Document, IDocument } from "../../Document";
import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IVerificationTaskActionVM {
    action: IVerificationTaskAction;
    verifier: string;
    taskProperty: string;
    raisedAgainst: string;
    verificationPoint: string;
    codePoints: string;
    historyCount: number;
    actionResponded: boolean;
    actionUploads: Array<IDocument>;
}

export class VerificationTaskActionVM implements IVerificationTaskActionVM {

    constructor(data?: IVerificationTaskActionVM) {
        if (data) this.update(data);
    }

    update(data: IVerificationTaskActionVM) {
        mapData(data, { 
            root: () => this,
            action: () => new VerificationTaskAction(),
            actionUploads: () => new Document()
        });
    }

    action: any = new VerificationTaskAction();
    verifier: string = "";
    taskProperty: string = "";
    raisedAgainst: string = "";
    verificationPoint: string = "";
    codePoints: string = "";
    historyCount: number = 0;
    actionResponded: boolean = false;
    actionUploads: Array<Document> = [];

    get landlordBlobColour() {
        if (this.isCompleted) {
            return "green";
        } else if (this.actionResponded) {
            return "green";
        } else return "red";
    }

    get verifierBlobColour() {
        if (this.isCompleted) {
            return "green"; 
        } else if (this.isDeleted) { 
            return "green"; 
        } else if (this.action.disputedReason == "") {
            return "green";
        } else if (this.action.disputedReason != "") {
            return "red";
        }
        else return "grey";
    }

    get isDeleted() {
        return this.action.deletedByUserID != utils.emptyGuidValue;
    }

    get isCompleted() {
        return this.action.completedByUserID != utils.emptyGuidValue;
    }
}
