import utils from "@/utilities/Utils";
import { AssessmentStatus } from "@/model/Enums";
import { mapData } from "@/utilities/DataMapping";

export interface IAssessment {
    id: string;
    saqID: string;
    landlordID: string;
    previousStatusID: string;
    statusID: string;
    status: AssessmentStatus;
    submittedDate: Date;
    submittedByUserID: string;
    expiryDate: Date;
    createdDate: Date;
    createdByUserID: string;
    lastModifiedDate: Date;
    lastModifiedByUserID: string;
}

export class Assessment implements IAssessment {

    constructor(data?: IAssessment) {
        if (data) this.update(data);
    }

    update(data: IAssessment) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    saqID: string = utils.emptyGuidValue;
    landlordID: string = utils.emptyGuidValue;
    previousStatusID: string = utils.emptyGuidValue;
    statusID: string = utils.emptyGuidValue;
    status: AssessmentStatus = AssessmentStatus.Archived;
    submittedDate: Date = new Date(utils.emptyDateValue);
    submittedByUserID: string = utils.emptyGuidValue;
    expiryDate: Date = new Date(utils.emptyDateValue);
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastModifiedDate: Date = new Date(utils.emptyDateValue);
    lastModifiedByUserID: string = utils.emptyGuidValue;
}
