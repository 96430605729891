import { ISAQQuestionUpload, SAQQuestionUpload } from "../../SAQQuestionUpload";
import { IAssessmentAnswerUpload, AssessmentAnswerUpload } from "../../AssessmentAnswerUpload";
import { Document, IDocument } from "../../Document";
import { mapData } from "@/utilities/DataMapping";

export interface IAssessmentAnswerUploadVM {
    questionUpload: ISAQQuestionUpload;
    answerUpload: IAssessmentAnswerUpload;
    uploads: Array<IDocument>;
}

export class AssessmentAnswerUploadVM implements IAssessmentAnswerUploadVM {

    constructor(data?: IAssessmentAnswerUploadVM) {
        if (data) this.update(data);
    }

    update(data: IAssessmentAnswerUploadVM) {
        mapData(data, { 
            root: () => this,
            questionUpload: () => new SAQQuestionUpload(),
            answerUpload: () => new AssessmentAnswerUpload(),
            uploads: () => new Document()
        });
    }

    questionUpload: any = new SAQQuestionUpload();
    answerUpload: any = new AssessmentAnswerUpload();
    uploads: Array<Document> = [];

    get blobColour() {
        if (this.answerUpload.completed && this.uploads.length > 0) {
            return "green";
        } else if (this.answerUpload.completed && this.uploads.length == 0) {
            return "#FFBF00";
        } else {
            return "red";
        }
    }
}
