import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ISAQSection {
    id: string;
    saqID: string;
    description: string;
    displayOrder: number;
    createdDate: Date;
    createdByUserID: string;
    lastModifiedDate: Date;
    lastModifiedByUserID: string;
}

export class SAQSection implements ISAQSection {

    constructor(data?: ISAQSection) {
        if (data) this.update(data);
    }

    update(data: ISAQSection) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    saqID: string = utils.emptyGuidValue;
    description: string = "";
    displayOrder: number = 0;
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastModifiedDate: Date = new Date(utils.emptyDateValue);
    lastModifiedByUserID: string = utils.emptyGuidValue;
}
