import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ISAQQuestionUpload {
    id: string;
    saqQuestionID: string;
    title: string;
    required: boolean;
}

export class SAQQuestionUpload implements ISAQQuestionUpload {

    constructor(data?: ISAQQuestionUpload) {
        if (data) this.update(data);
    }

    update(data: ISAQQuestionUpload) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    saqQuestionID: string = utils.emptyGuidValue;
    title: string = "";
    required: boolean = false;
}
