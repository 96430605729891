import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IAssessmentAnswer {
    id: string;
    assessmentSectionID: string;
    saqQuestionID: string;
    questionNo: number;
    responseID: number;
    supportingText: string;
    visitResponseComplete: boolean;
    verifierResponseID: string;
    verifierComments: string;
}

export class AssessmentAnswer implements IAssessmentAnswer {

    constructor(data?: IAssessmentAnswer) {
        if (data) this.update(data);
    }

    update(data: IAssessmentAnswer) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    assessmentSectionID: string = utils.emptyGuidValue;
    saqQuestionID: string = utils.emptyGuidValue;
    questionNo: number = 0;
    responseID: number = 0;
    supportingText: string = "";
    visitResponseComplete: boolean = false;
    verifierResponseID: string = utils.emptyGuidValue;
    verifierComments: string = "";

    get responseButtonText() {
        if (this.responseID != 0) {
            return "Edit Response";
        } else return "Add Response";
    }
}
